<template>
    <div class="memberEdit table-bg" v-loading="loading">
        <div class="table-header">
            <div class="el_left btns">
                <template v-if="showCheck">
                    <el-button size="mini" @click="handleChooseUser">
                        选择用户
                    </el-button>
                    <el-button size="mini" @click="handleDeleteUser">
                        取消用户
                    </el-button>
                </template>
                <template v-else>
                    <el-button size="mini" @click="confirmSelection">
                        确认选择
                    </el-button>
                </template>
            </div>
            <div class="el_right">
                <slot>
                    <div class="el_left conditionQuery">
                        <el-input placeholder="请输入搜索内容..." v-model="generalQueryText" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="generalQuery"></el-button>
                        </el-input>
                    </div>
                </slot>
                <div class="el_left setUp">
                    <el-button v-popover:popover>
                        <i class="iconfont iconshezhi"></i>
                    </el-button>
                    <el-popover
                        ref="popover"
                        placement="bottom-start"
                        title="自定义列表项"
                        width="540"
                        trigger="click"
                        v-model="isPopover"
                    >
                        <div class="tablePopover">
                            <div class="popoverContent">
                                <el-checkbox-group v-model="selectListItem">
                                    <template v-for="(item, index) in listDisplayItems">
                                        <el-checkbox :key="index" :label="item.field">
                                            {{ item.title }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                            <div class="el_right">
                                <el-button size="mini" @click="isPopover = false">
                                    取消
                                </el-button>
                                <el-button size="mini" type="primary" @click="confirmListItem">
                                    确定
                                </el-button>
                            </div>
                        </div>
                    </el-popover>
                </div>
            </div>
        </div>
        <el-table
            border
            stripe
            :data="tableList"
            ref="multipleTable"
            @row-click="selateTable"
            :highlight-current-row="true"
            row-key="User_Id"
        >
            <el-table-column
                key="select"
                type="selection"
                :reserve-selection="true"
                width="55"
            >
            </el-table-column>
            <template v-for="(item,index) in tableHeader">
                <el-table-column
                    :key="index"
                    :prop="item.field"
                    :label="item.title"
                    :width="item.width"
                    show-overflow-tooltip
                >
                </el-table-column>
            </template>
        </el-table>
        <div class="table-page">
            <div class="el_right">
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[20,40,60,80,100]"
                    :page-size="limit"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'currency-table',
    components: {},
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            // 是否展示已选择用户数据
            showCheck: false,
            loading: false,
            // 查询条件
            generalQueryText: '',
            // 自定义列表项数据
            listDisplayItems: [],
            // 已选中显示项
            selectListItem: [],
            initSelectId: [],
            // 列表项选择弹出层状态
            isPopover: false,
            // 列表头部数据
            tableHeader: [],
            // 列表数据
            tableList: [],
            // 数据总条数
            total: 0,
            // 当前页码
            page: 1,
            // 每页显示条目个数
            limit: 20,
            // 列表选择数据集合
            tableSelectData: [],
            tableData: {
                showCheckBox: true,
                isShowNo: true,
                headerData: [{
                    title: '用户ID',
                    field: 'User_Id',
                    width: 300,
                }, {
                    title: '登录名',
                    field: 'Login_Name',
                }, {
                    title: '用户名',
                    field: 'User_Name',
                }, {
                    title: '描述',
                    field: 'Desc',
                }, {
                    title: '排序号',
                    field: 'Sort_Num',
                }, {
                    title: '创建时间',
                    field: 'Create_Time',
                }, {
                    title: '更新时间',
                    field: 'Update_Time',
                }],
                requestUrl: '/basicplatform/usermenu/get_org_user',
                requestParameter: '',
            },
        };
    },
    watch: {},
    computed: {},
    created() {
        if (this.extr && this.extr.roleId) {
            this.showCheck = this.extr.showCheck;
            this.tableData.requestParameter = '&Role_Id=' + this.extr.roleId + '&is_check=' + this.extr.showCheck;
            this.initSelectId = Object.assign([], this.extr.selectId || []);
        }
        this.listDisplayItems = this.tableData.headerData;
        this.selectListItem = this.tableData.headerData.map(item => item.field);
        this.tableHeader = this.tableData.headerData;
        this.getTableData();
    },
    methods: {
        // 点击行选中
        selateTable(row) {
            this.$refs.multipleTable.toggleRowSelection(row);
        },
        // 确认选择列表显示项
        confirmListItem() {
            const newColumns = [];
            this.listDisplayItems.forEach(k => {
                this.selectListItem.forEach(item => {
                    if (item === k.field) {
                        newColumns.push(k);
                    }
                });
            });
            this.tableHeader = newColumns;
            this.isPopover = false;
        },
        // 设置每页条数
        handleSizeChange(val) {
            this.limit = val;
            this.getTableData();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.page = val;
            this.getTableData();
        },
        // 查询
        generalQuery() {
            this.getTableData();
        },
        // 获取列表数据
        getTableData() {
            this.loading = true;
            this.request_Get();
        },
        // get请求
        request_Get() {
            let requestUrl = `/interfaceApi${this.tableData.requestUrl}?&pageindex=${this.page}&pagesize=${this.limit}`;
            if (this.generalQueryText !== '') {
                requestUrl = requestUrl + `&search=${this.generalQueryText}`;
            }
            if (this.tableData.requestParameter !== '') {
                requestUrl = requestUrl + this.tableData.requestParameter;
            }
            this.$axios
                .get(requestUrl)
                .then(res => {
                    this.$set(this, 'tableList', res.rows);
                    // 数据总条数
                    this.total = res.total;
                    // 强制刷新
                    this.$forceUpdate();
                    // 设置选中
                    this.initPageSelect();
                    this.loading = false;
                });
        },
        // 处理带过来的 选中用户id
        initPageSelect() {
            this.tableList.forEach(row => {
                const index = this.initSelectId.indexOf(row.User_Id);
                if (index !== -1) {
                    this.initSelectId.splice(index, 1);
                    this.$refs.multipleTable.toggleRowSelection(row, true);
                }
            });
        },
        // 获取选择的userId
        getSelectUserIds() {
            const selectData = this.$refs.multipleTable.selection;
            const userIds = [];
            selectData.forEach(item => {
                if (!userIds.includes(item.User_Id)) {
                    userIds.push(item.User_Id);
                }
            });
            return userIds;
        },
        // 确认选择
        confirmSelection() {
            const userIds = this.getSelectUserIds();
            this.$axios.post('/interfaceApi/basicplatform/usermenu/add_org_user', {
                User_Ids: userIds,
                Role_Id: this.extr.roleId,
            })
                .then(data => {
                    if (data) {
                        this.$refs.multipleTable.clearSelection();
                        this.tableSelectData = [];
                        this.$parent.hide();
                        if (this.extr.sureCallback) {
                            this.extr.sureCallback();
                        }
                        if (this.extr.parentExtrSureCallback) {
                            this.extr.parentExtrSureCallback();
                        }
                        this.$message.success('保存成功');
                    }
                }).catch(e => {
                    this.$message.error(e.ErrorCode.Message);
                });
        },
        // 选择用户
        handleChooseUser() {
            this.$toast(
                {
                    title: true,
                    text: '选择成员',
                    type: 'eject',
                    t_url: 'admin/org/memberEdit',
                    extr: {
                        showCheck: false,
                        roleId: this.extr.roleId,
                        parentExtrSureCallback: this.extr.sureCallback,
                        sureCallback: this.getTableData,
                    },
                    viewPosition: '',
                }
            );
        },
        // 删除用户
        handleDeleteUser() {
            this.$confirm('确认取消用户?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                const userIds = this.getSelectUserIds();
                this.$axios.post('/interfaceApi/basicplatform/usermenu/del_org_user', {
                    User_Ids: userIds,
                    Role_Id: this.extr.roleId,
                })
                    .then(data => {
                        if (data) {
                            this.$refs.multipleTable.clearSelection();
                            this.tableSelectData = [];
                            this.$message.success('取消成功');
                            this.getTableData();
                            if (this.extr.sureCallback) {
                                this.extr.sureCallback();
                            }
                        }
                    }).catch(e => {
                        this.$message.error(e.ErrorCode.Message);
                    });
            }).catch(() => {
            });

        },
    },
};
</script>

<style lang="stylus">
    .memberEdit
        height 100%
        padding 0.14rem
        background #fff

        .table-header
            height 0.51rem

            .btns
                float left

                .el-button
                    color #fff
                    background #2978ff
                    padding 0 0.2rem
                    border none
                    line-height 0.36rem

            .conditionQuery
                width 3.6rem

                .el-input-group__append
                    background #1577d2
                    border 1px solid #1577d2

                    .el-button
                        color #fff

            .setUp
                .el-button
                    width 0.36rem
                    height 0.36rem
                    padding 0
                    color #fff
                    font-size 0.16rem
                    background #1577d2
                    margin-left 0.1rem

        .el-table
            height calc(100% - 0.95rem)

            th, td
                padding 0

            .editList
                > div
                    vertical-align top

                .el-date-editor
                    width 100% !important
</style>
